.all-tickets-container {
  width: 98%;
  margin-left: auto;
  margin-right: auto;
}

.all-tickets-card {
  box-shadow: 0px 0px 10px rgb(204, 201, 201);
  padding: 1rem 2rem;
  border-radius: 15px !important;
  box-shadow: 5px 5px 20px 5px rgb(0 0 0 / 10%);
}

.bg-total {
  background: #4f71dd;
  color: #fff !important;
}

.bg-closed {
  background: #1dc789;
  color: #fff !important;
}

.bg-waiting {
  background: #37b8cd;
  color: #fff !important;
}

.bg-open {
  background: #fac63e;
  color: #fff !important;
}

.icon-btn {
  cursor: pointer !important;
  /* background-color: #DC3545; */
  color: #dc3545;
  border-radius: 10px !important;
}

/* For date filter css */

.date-filter > div {
  display: flex !important;
}

.date-picker > div > div > input {
  padding: 10px !important;
}
