.wrapper {
  height: 100%;
}
.side-bar {
  background-color: #ffffff !important;
  margin-bottom: 30px !important;
  padding: 50px 30px !important;
  color: black;
  /* height: 200px; */
  border-radius: 20px;
  box-shadow: 5px 5px 20px 5px rgb(0 0 0 / 10%);
  cursor: pointer;
  transition: 0.4s;
}

.pills-item {
  border-bottom: 1px solid #d8e9ff !important;
  border-radius: 10px !important;
  box-shadow: 5px 5px 20px 5px rgb(0 0 0 / 10%) !important;
  margin: 5px 0px;
}

.main-content {
  background-color: #ffffff !important;
  margin-bottom: 30px !important;
  padding: 15px 10px !important;
  color: black;
  border-radius: 20px;
  box-shadow: 5px 5px 20px 5px rgb(0 0 0 / 10%);
  cursor: pointer;
  transition: 0.4s;
}
