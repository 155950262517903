@media screen and (max-width: 400px) {
  .for-mobile-screen {
    height: auto !important;
    margin-top: 0.3rem;
  }
}

@media screen and (max-width: 766px) {
  .for-mobile-screen {
    height: auto !important;
    margin-top: 0.3rem;
  }
}

.dates-input {
  border: 1px solid #ced4da !important;
}
.dates-input:active {
  border: 1px solid #d4d7db !important;
}
.dates-input:focus {
  border: 1px solid #d4d7db !important;
}
.dates-input:visited {
  border: 1px solid #d4d7db !important;
}

.ticket-form {
  border-radius: 15px !important;
  /* background-color: #dae2e9; */
  padding: 40px;
}
.ticket-form > form > div > div > label {
  font-weight: 600 !important;
  font-size: 15px !important;
}
.ticket-form > form > div > div > input {
  border-radius: 8px !important;
  background-color: #f3f8ff;
}

.ticket-form
  > form
  > div
  > div
  > div
  > textarea {
  border-radius: 8px !important;
  background-color: #f3f8ff;
}
.ticket-form > form > div > div > select {
  border-radius: 8px !important;
  background-color: #f3f8ff;
}
.ticket-form
  > form
  > div
  > div
  > select
  > option {
  border-radius: 8px !important;
  background-color: #f3f8ff;
}
.ticket-form > form > div > div > div {
  border-radius: 8px !important;
  background-color: #f3f8ff;
}

.hover-on-date > div > div > fieldset {
  border: none !important;
}
.hover-on-date > div > div > fieldset :hover {
  border: none !important;
}
