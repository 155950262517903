/* ------------------------------------------------------ */
/* ############### 1. GOOGLE FONTS IMPORT ############### */
/* ------------------------------------------------------ */
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700");
@import url("https://fonts.googleapis.com/css?family=Fira+Sans:100,200,300,400,500,600,700");
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700");
@import url("https://fonts.googleapis.com/css?family=Hind:300,400,500,600,700");
@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700,900");
@import url("https://fonts.googleapis.com/css?family=Libre+Barcode+39+Extended+Text");

:root {
  --gradient-text: linear-gradient(89.97deg, #ae67fa 1.84%, #f49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #ae67fa -13.86%, #f49867 99.55%);

  --card-blue-light: #33a9fe;
  --card-yellow-light: #fac762;
  --card-green-light: #35df91;
  --card-orange-light: #f47960;

  --card-blue-medium: #0090f6;
  --card-yellow-medium: #f0ae2c;
  --card-green-medium: #1fba73;
  --card-orange-medium: #e74e31;

  --card-blue-dark: #0273c5;
  --card-yellow-dark: #e49807;
  --card-green-dark: #198f57;
  --card-orange-dark: #d6300f;

  --text-white: #fff;

  --color-bg: #d5e6ff;
  --color-footer: #031b34;
  --color-blog: #042c54;
  --color-text: #81afdd;
  --color-subtext: #ff8a71;
  --btn-primary-color: #43cd63;
  --btn-secondary-color: #0058af;
}
* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ht-100v {
  height: 100vh;
}

.heading {
  font-weight: bold !important;
}
.primary--color {
  color: #1479ff !important;
}

.primary-bg-color {
  background-color: #1479ff !important;
}

.primary-border-color {
  border: 1px solid #1479ff !important;
}
.btn--primary {
  background-color: #1479ff !important;
  color: #fff !important;
  border-radius: 10px !important;
}

.btn--primary:hover,
.btn--primary:active,
.btn--primary:focus {
  background-color: #1479ff !important;
  color: #fff !important;
  border-radius: 10px !important;
  -webkit-transform: translateY(-2px) !important;
  -ms-transform: translateY(-2px) !important;
  transform: translateY(-2px) !important;
}

.btn--primary-outline {
  background-color: #fff !important;
  color: #1479ff !important;
  border: 1px solid #1479ff !important;
  border-radius: 5px !important;
}

.btn--primary-outline:hover,
.btn--primary-outline:active,
.btn--primary-outline:focus {
  background-color: #1479ff !important;
  color: #fff !important;
  border-radius: 5px !important;
}

.secondary--color {
  color: #d5e6ff !important;
}

.secondary-bg-color {
  background-color: #d5e6ff !important;
}

.secondary-border-color {
  border: 1px solid #d5e6ff !important;
}

.bg-color-light {
  background: #f3f8ff !important;
}

.padding--20px {
  padding: 15px 20px !important;
}

.pri-border-radius {
  border-radius: 15px;
}

.sec-border-radius {
  border-radius: 15px;
}
